import React from 'react';
import { Col, Card } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import {useNavigate} from "react-router-dom";
import { DEVICE_ROUTE } from '../utils/consts';
import '../components/css/device_item.css';



const DeviceItem = ({device}) => {
    const navigate = useNavigate()
    return (
        <Col md={3} className={"mt-3"} onClick={() => navigate(DEVICE_ROUTE + '/' + device.id)}>
            <Card className="device-card">
                <Image className="device-img"  src={process.env.REACT_APP_API_URL + device.img}/>
                
                <div className="device-name">{device.name}</div>
            </Card>
        </Col>
    );
};

export default DeviceItem;