import React from "react";
import Carousel from "./Carousel"; 
//import Parallax from "./Parallax";
import BlockHome from "./BlockHome";
import CarouselHome from "./CarouselHome";
import ContainerHome1 from "./ContainerHome1";
import ContainerHome2 from "./ContainerHome2";
import ContainerHome3 from "./ContainerHome3";
import Texts from "./Texts" 
import Hello from "./HelloHome";
import Resume from "./Data/Resume";

import { RESUME_ROUTE } from "../utils/consts";
import {useNavigate} from 'react-router-dom';


import '../components/css/blockhome.css';



const Home = () => {
    const navigate = useNavigate()

    return (
        <div>
            <Texts />
            <Hello />
            <ContainerHome1 /> 
            <ContainerHome2 />            
            <Carousel />
            <BlockHome/>
            <CarouselHome />
            <ContainerHome3 />
            <div className="copyright" onClick={() => navigate(RESUME_ROUTE)}><p>© Larkov.org {new Date().getFullYear()}  </p>      
            </div>
        </div>
    );
};

export default Home;
