import React, {useContext, useState} from "react";
import { Container, Form, Card } from "react-bootstrap";
import { LOGIN_ROUTE, REGISTRATION_ROUTE, SHOP_ROUTE } from "../utils/consts";
//import Row from "react-bootstrap/Row";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import { login, registration } from "../http/userAPI";
import {observer} from "mobx-react-lite";
//import UserStore from "../store/UserStore";
import {Context} from "../index";
import '../components/css/auth.css';
import '../fonts/Golos-UI_Medium.css';


const Auth = observer(() => {
    const {user} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = location.pathname === LOGIN_ROUTE
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const click = async () => {
        try {
            let data;
            if (isLogin) {
                data = await login(email, password);
            } else { 
                data = await registration(email, password);
            }
            user.setUser(data)
            user.setIsAuth(true)
            navigate(SHOP_ROUTE)
        } catch (e) {
            alert(e.response.data.message)
        }
    }


    return (
        <Container
            className="container-auth"
            style={{height: window.innerHeight - 54}}>
            
            <Card style={{width: 400}} className="card-auth">
                <h2 className="m-auto">{isLogin ? 'Авторизация' : 'Регистрация'}</h2>
                <Form className="d-flex flex-column">
                    <Form.Control
                        className="mt-3" style={{color: "#4E4E4E"}}
                        placeholder="введите ваш email..." 
                        value={email}
                        onChange={e => setEmail(e.target.value)}                   
                    />
                    <Form.Control
                        className="mt-3"
                        placeholder="введите ваш пароль"   
                        value={password}
                        onChange={e => setPassword(e.target.value)}  
                        type="password"               
                    />
                    <Form className="d-flex justify-content-between mt-3 pl-3 pr-3">
                        {isLogin ?
                            <div> 
                                нет аккаунта? 
                                <NavLink 
                                  to={REGISTRATION_ROUTE}
                                  style={{textDecoration: "none"}}
                                  > Регистрация
                                </NavLink>
                            </div>
                            :
                            <div>
                                есть аккаунт?
                                <NavLink
                                  to={LOGIN_ROUTE}
                                  style={{textDecoration: "none"}}
                                  > Войдите!
                                </NavLink>
                            </div>
                        }
                        <Button
                           variant={"outline-light"}
                           className="custom-button"
                           onClick={click}>
                           {isLogin ? 'Войти' : 'Регистрация'}
                        </Button>

                    </Form>
                </Form>
            </Card>
        </Container>
    );
});

export default Auth;
