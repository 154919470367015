import React from "react";
import '../components/css/hello.css';

function Hello() {
  return (
    <div className="hello-container">
      <h1 className="hello-typing-text">
         Добро пожаловать в наш магазин авторских блокнотов и значков!<br />
         Мы создаем уникальные изделия, которые не только красивы и функциональны,
         но и отражают вашу индивидуальность.
      </h1>
    </div>
  );
}

export default Hello;
