import React, { useState, useEffect } from 'react';
import '../components/css/carousel.css';

const images = [
    require('./images/image10.jpg'),
    require('./images/image2.jpg'),
    require('./images/image4.jpg'),
    require('./images/image1.jpg'),
    require('./images/image5.jpg'),
    require('./images/image6.jpg'),
    require('./images/image7.jpg')
  ];
  

const Carousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // устанавливаем интервал для автоматического переключения изображений
    const intervalId = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId); // очищаем интервал при размонтировании компонента
  }, [currentImageIndex]);

  return (
    <div className="carousel-container">
      {images.map((imageUrl, index) => (
        <div
          key={imageUrl}
          className={`carousel-image ${index === currentImageIndex ? 'active' : ''}`}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      ))}
    </div>
  );
};

export default Carousel;