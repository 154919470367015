import React from 'react';
import '../components/css/container_home.css';
import { Image } from 'react-bootstrap';
import image5 from '../components/images/image5.jpg';

const ContainerHome1 = () => {
  return (
    <div className="container-home">
      <div className="photo-container-home">
        <Image src={image5} className="img-container-home" /> 
      </div>
      <div className="text-container-home">
          <p>Наши блокноты и значки изготавливаются вручную с любовью и заботой о деталях.
             Перед вами широкий выбор дизайнов и размеров, которые помогут вам выразить себя 
             и вдохновят на творчество. Мы уверены, что найдете у нас что-то, что подходит именно вам.</p>
      </div>      
    </div>
  );
};

export default ContainerHome1;
