import React from "react";
import '../components/css/blockhome.css';


const BlockHome = () => {
    return (
        <div>
            <h1 className="text-block-home ">
            Покупая блокноты у нас, вы не только получаете качественный продукт,
            но и делаете свой вклад в охрану окружающей среды. 
            </h1>                                      
       </div>              
    );
};

export default BlockHome;