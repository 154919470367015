import { observer } from "mobx-react-lite";
import React, { useContext } from 'react';
import { Context } from "..";
import { ListGroup } from "react-bootstrap";
import '../components/css/type_bar.css';

const TypeBar = observer(() => {
    const {device} = useContext(Context)
    return (
        <ListGroup className="type_bar">
           {device.types.map(type => 
            <ListGroup.Item
             style={{cursor: 'pointer'}}
             active={type.id === device.selectedType.id}
             onClick={() => device.setSelectedType(type)}
             key={type.id}
             >
                {type.name}
            </ListGroup.Item>)}
        </ListGroup>
    );
});

export default TypeBar;