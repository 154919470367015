import React from "react";
import '../components/css/basket.css';
import Image from 'react-bootstrap/Image';
import boxcat from '../components/images/boxcat.png';


const Basket = () => {
    return (
        <div className="boxcat">
            <div className="inner-container">
               <div className="text">корзина временно не работает</div>
               <Image src={boxcat}  className="image" />               
            </div>
        </div>
    );
};

export default Basket;