import React from "react";
import '../components/css/texts.css';

function Texts() {
  return (
    <div className="texts-container">
      <h1 className="texts-typing-text">Запиши свои идеи с нами.</h1>
    </div>
  );
}

export default Texts;
