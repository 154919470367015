import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { createType, deleteType, getAllTypes } from '../../http/deviceAPI';

const CreateType = ({ show, onHide }) => {
  const [value, setValue] = useState('');
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    getAllTypes()
      .then((data) => {
        setTypes(data);
      })
      .catch((error) => {
        console.error('Error getting types:', error);
      });
  }, []);

  const addType = () => {
    createType({ name: value }).then((data) => {
      setValue('');
      onHide();
    });
  };

  const deleteSelectedType = () => {
    if (selectedType) {
      deleteType(selectedType)
        .then((data) => {
          console.log('Type deleted successfully.');
          setSelectedType(null);
        })
        .catch((error) => {
          console.error('Error deleting type:', error);
        });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить тип
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={'Введите название типа'}
          />
        </Form>
        <DropdownButton id="dropdown-basic-button" title="Выбрать тип">
          {types.map((type) => (
            <Dropdown.Item
              key={type.id}
              onSelect={() => setSelectedType(type.id)}
            >
              {type.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={deleteSelectedType}>
          Удалить тип
        </Button>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button variant="outline-success" onClick={addType}>
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateType;
