import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import { observer } from "mobx-react-lite";
import { Context } from "./index";
import {check} from "./http/userAPI";
import MySpinner from "./components/MySpinner";





const App = observer(() => {
  const {user} = useContext(Context)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
        check().then(data => {
            user.setUser(true)
            user.setIsAuth(true)
        }).finally(() => setLoading(false))
        }, [user])

    if (loading) {
        return <MySpinner color="primary"/>
    }


  return (
    <BrowserRouter>
      <NavBar />      
      <AppRouter />       
            
      
    </BrowserRouter>


  );
});

export default App;


//import ImageSlider from "./components/ImageSlider";
//import Drop from "./components/Drop";