import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row, Card, Button } from "react-bootstrap";
import {useParams} from 'react-router-dom'
import {fetchOneDevice} from "../http/deviceAPI";
import '../components/css/device_page.css';

const DevicePage = () => {
   const [device, setDevice] = useState({info: []})
   const {id} = useParams()

   useEffect(() => {
    fetchOneDevice(id).then(data => setDevice(data))
   }, [id])

    return (
        <Container className="mt-2">
            <Row>
                <Col md={4}>
                    <h2 className="device_name">{device.name}</h2>
                    <Image className="device_img" src={process.env.REACT_APP_API_URL + device.img}/>
                </Col>
                <Col md={8} className="device_container">
                    <Row>
                       <Col md={6}>
                          <Card className="device_card">
                          <h3>{device.price} &#8381;</h3>
                          <Button className="device_button" variant={"outline-dark"}>в корзину</Button>
                          </Card>  
                       </Col>
                    </Row>
                    <Row>
                       <Col md={6} className="device_info">
                       <div className="d-flex flex-column m-2">
                       {device.info.map(info =>
                       <div key={info.id}>
                       {info.title}: {info.description}
                       </div>
                       )}
                       </div>
                       </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
           
    );
};

export default DevicePage;
