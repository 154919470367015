import React, { useState, useEffect } from 'react';
import '../components/css/carousel_home.css';

const images = [
  require('./images/image1.jpg'),
  require('./images/image2.jpg'),
  require('./images/image3.jpg')
];

const CarouselHome = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // устанавливаем интервал для автоматического переключения изображений
    const intervalId = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 9000);

    return () => clearInterval(intervalId); // очищаем интервал при размонтировании компонента
  }, [currentImageIndex]);

  return (
    <div className="carousel-container-home">
      {images.map((imageUrl, index) => (
        <div
          key={imageUrl}
          className={`carousel-image-home ${index === currentImageIndex ? 'active' : ''}`}
          style={{
            backgroundImage: `url(${imageUrl})`,
            left: `${((index - currentImageIndex + images.length) % images.length) * 100}%` // добавляем зацикливание и смещение слева на право
          }}
        />
      ))}
    </div>
  );
};

export default CarouselHome;
