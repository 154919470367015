import React from "react";
import '../css/resume.css';
import ExternalLinkIcon from "./ExternalLinkIcon";
import github from '../images/github.png';
import telegram from '../images/telegram.png';
import insta from '../images/insta.png';
import logo5 from '../images/logo5.png';


function Resume () {
  return (
    <div className="container_resume">
      <div className="personal-info">
        <h4>Ларьков Сергей Михайлович</h4>
        <p>Телефон: 8910 929 2245</p>
        <p>Email: sirech@mail.ru</p>
        <p>Адрес: Кострома</p>
        <div>
          <ExternalLinkIcon 
             url="https://github.com/Sirech-hub"
             icon={github}
             alt="Github"
          />
          <ExternalLinkIcon
             url="https://t.me/@SLarkov"
             icon={telegram}
             alt="Telegram"
          />
          <ExternalLinkIcon
             url="https://instagram.com/_sirech_?igshid=MzNlNGNkZWQ4Mg=="
             icon={insta}
             alt="Instagramm"
          />
        </div>
      </div>
      <div className="education">
        <h3>Образование</h3>
        <ul>
          <li>самообразование: книги, интернет</li>
        </ul>
      </div>
      <div className="work-experience">
        <h3>Опыт работы</h3>
        <ul>
          <li>10.2008 - 5.2009<br />
              Место: ЗАО "Альфа Софт"<br />
              Должность: инженер <br />
              Обязанности: занимался ремонтом оргтехники и компьютерных комплектующих,<br />
              сборкой и настройкой пк, администрированием сетей.
          </li>
          <li>07.2010 - по настоящее время <br />
               Место: ИП Соловьв А.Н. "Сота Сервис"<br />
               Должность: инженер по ремонту цифровой электроники <br />
               Обязанности: ремонт на уровне железа (смартфоны, планшеты, и аналогичные устройства)<br />
               от модульной замены до замены SMD, BGA(CSP) / программный ремонт, восстановление ПО,<br />
               разблокировка FRP / логистика комплектующих и работа с поставщиками.
          </li>
        </ul>
      </div>
      <div className="skills">
        <h3>Навыки</h3>
        <ul>
          <li>Oсновы тестирования UI, API, UNIT тесты</li>
          <li>Базовые знания: JavaScript, React-JS, HTML, CSS, REST API, POSTMAN, Corel-Draw </li>
        </ul>
      </div>
      <div className="projects">
        <h3>Experimental project</h3>
        <ul className="projects-li">
          <li>
          <ExternalLinkIcon
             url="http://sirech.beget.tech/"
             icon={logo5}
             alt="ololo"
          /> Фотогалерея (Python, Django, HTML, CSS, JS) 2021
          </li>
          <li>
          <ExternalLinkIcon
             url="http://waytomars.ru/"
             icon={logo5}
             alt="ololo"
          /> Доска задач (React JS, Redux, Material-ui, React-beautiful-dnd ) 2021
          </li>
          <li>
          <ExternalLinkIcon
             url="https://sirech-hub.github.io/Diamaint/"
             icon={logo5}
             alt="ololo"
          /> Макет Figma (HTML, Gulp, SCSS, Figma, БЭМ) 2022
          </li>
          <li>
          <ExternalLinkIcon
             url="https://larkov.org"
             icon={logo5}
             alt="Instagramm"
          /> Online store (React JS, Axios, MobX, CSS, Node JS, Express, PostgreeSQL, Sequilize ) 2023 (в процессе)
          </li>
        </ul>
      </div>
      <div className="additional-info">
        <h3>Дополнительная информация</h3>
        <p>О себе: критическое мышление, уситчивость. Увлекаюсь: фотографией, программированием,  системами с ЧПУ.</p>
      </div>
    </div>
  );
}

export default Resume;