import React from "react";
import '../components/css/blockhome.css';



const ContainerHome3 = () => {
    return (
        <div>
            <h1 className="text-block-home ">
            Благодарим за внимание к нашей продукции и надеемся,
            что наши блокноты станут незаменимым помощником для вас.
            </h1>                          
            
       </div>              
    );
};

export default ContainerHome3;