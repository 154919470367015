import React from 'react';
import '../components/css/container_home.css';
import { Image } from 'react-bootstrap';
import image4 from '../components/images/image4.jpg';

const ContainerHome2 = () => {
  return (
    <div className="container-home">
      <div className="text-container-home">
          <p>Мы верим, что блокнот - это не просто инструмент для записи заметок,
           но и предмет, который может стать частью вашего образа жизни.
           Наши блокноты отличаются не только качеством, но и стилем,
           который может подчеркнуть вашу индивидуальность.</p>
      </div> 
      <div className="photo-container-home">
        <Image src={image4} className="img-container-home" /> 
      </div>
           
    </div>
  );
};

export default ContainerHome2;
